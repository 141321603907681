import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { Bar, Button } from 'rf-ui';
import { People } from 'styled-icons/material';
import { useInterval, get, isAuthorized } from 'rf-lib';
import { TicketAlt } from 'styled-icons/fa-solid';
import { Clock } from 'styled-icons/fa-regular';

const LogoTitle = styled.div`
  display: flex;
  align-items: center;

  img {
    background: black;
  }

  h3 {
    margin-left: ${get('spacingLg')};
  }
`;

const GameInfo = styled.div`
  display: flex;
  align-items: center;

  > div:not(:last-child) {
    margin-right: ${get('spacingLg')};
  }
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2px;
  svg {
    margin-right: ${get('spacingSm')};
  }
`;

const Players = ({ attending }) => (
  <InfoContainer>
    <People size="2rem" />
    <span>{attending}</span>
  </InfoContainer>
);

Players.propTypes = {
  attending: PropTypes.number.isRequired,
};

const Tickets = ({ purchased, maxTickets }) => (
  <InfoContainer>
    <TicketAlt size="2rem" />
    <span>{purchased} / {maxTickets}</span>
  </InfoContainer>
);

Tickets.propTypes = {
  purchased: PropTypes.number.isRequired,
  maxTickets: PropTypes.number.isRequired,
};

const formatStartTime = secondsUntil => {
  const days = Math.floor(secondsUntil / 60 / 60 / 24);
  const hours = Math.floor(secondsUntil / 60 / 60) % 24;
  const minutes = Math.floor(secondsUntil / 60) % 60;
  const seconds = Math.trunc(secondsUntil % 60);

  if (days >= 1) return `${days}d ${hours}h`;

  return `${hours}t ${minutes}m ${seconds}s`;
};

const diffSeconds = date =>
  (date.getTime() - Date.now()) / 1000;

const CountDown = ({ until, completeMsg }) => {
  const startDiff = diffSeconds(until);
  const [secondsUntil, setUtcUntil] = useState(startDiff);

  useInterval(() => {
    setUtcUntil(diffSeconds(until));
  }, [1000]);

  return secondsUntil > 0
    ? <span>{formatStartTime(secondsUntil)}</span>
    : secondsUntil < 1800 ? <span>Ferdig</span> : <span>I gang</span>;
};

CountDown.propTypes = {
  until: PropTypes.instanceOf(Date).isRequired,
  completeMsg: PropTypes.string.isRequired
};

const StartsIn = ({ startTime, completeMsg }) => (
  <InfoContainer>
    <Clock size="2rem" />
    <CountDown until={startTime} completeMsg={completeMsg} />
  </InfoContainer>
);

StartsIn.propTypes = {
  startTime: PropTypes.instanceOf(Date).isRequired,
  completeMsg: PropTypes.string.isRequired
};

const EditButton = ({ game }) => {
  const history = useHistory();

  const goToEditGamePage = ev => {
    history.push(`/game/edit/${game.uid}`);
    ev.preventDefault();
  };

  return (<Button title="Edit" onClick={goToEditGamePage} />);
};

EditButton.propTypes = {
  game: PropTypes.object.isRequired,
};

const GameItem = ({
  game, authUser, groups, complete, ...props
}) => (
  <Link to={`/game/${game.uid}`}>
    <Bar>
      <LogoTitle>
        {game.imageUrl ? (
          <img src={game.imageUrl} alt="img" width="70px" height="60px" />
        ) : <People width="70px" height="60px" />}
        <h3>{game.name}</h3>
      </LogoTitle>
      {complete === true? (
        <GameInfo>
          <p>{game.groupName}</p>
          <Players attending={game.attending} />
          <Tickets purchased={game.ticketsPurchased} maxTickets={game.maxTickets} />
          {game.startTime && <StartsIn startTime={game.startTime} completeMsg="I gang" />}
        </GameInfo>
      ) : (
        <GameInfo>
          <p>{game.groupName}</p>
          <Players attending={game.attending} />
          <Tickets purchased={game.ticketsPurchased} maxTickets={game.maxTickets} />
          {game.startTime && <StartsIn startTime={game.startTime} completeMsg="I gang" />}
          {isAuthorized('admin', game.groupID, authUser) && <EditButton game={game} />}
        </GameInfo>
      )}
    </Bar>
  </Link>
);

GameItem.propTypes = {
  game: PropTypes.object.isRequired,
  groups: PropTypes.array,
  authUser: PropTypes.object,
  complete: PropTypes.bool
};

export default (GameItem);
