import * as groupsApi from './groups';
import * as playersApi from './players';
import firebase from '../firebase/firebase';

const groupGamesRef = groupId => firebase.db.ref(`groupGames/${groupId}`);

const groupGameRef = (groupId, gameId) => firebase.db.ref(`groupGames/${groupId}/${gameId}`);

const gamesRef = () => firebase.db.ref('games');

const gameRef = gameID => firebase.db.ref(`games/${gameID}`);

const ticketsRef = gameId => firebase.db.ref(`games/${gameId}/tickets`);

const ticketRef = (gameId, playerId) => firebase.db.ref(`games/${gameId}/tickets/${playerId}`);

const gameRunnerRef = gameID => firebase.db.ref(`gameRunner/${gameID}`);

const gameStarterRef = gameID => firebase.db.ref(`gameStarter/${gameID}`);

export const transactionRef = (groupId, uid, gameId) => firebase.db.ref(`groupTransactions/${groupId}/${uid}/${gameId}`);

export const startGame = gameId => gameStarterRef(gameId).set({ [gameId]: gameId });

const addGameToGroup = (groupId, gameId) => {
  groupGamesRef(groupId)
    .once('value')
    .then(snapshot => ({
      ...snapshot.val(),
      [gameId]: true
    }))
    .then(updatedFields => groupGamesRef(groupId).update(updatedFields));
};

const mapToFirebaseGame = gameForm => ({
  gameName: gameForm.gameName,
  groupID: gameForm.groupID,
  startTime: gameForm.startTime.getTime(),
  ticketsPurchased: gameForm.ticketsPurchased,
  maxTickets: gameForm.maxTickets,
  maxTicketsPerPlayer: gameForm.maxTicketsPerPlayer,
  numberOfPrizes: gameForm.numberOfPrizes,
  ticketPrice: gameForm.ticketPrice,
});

export const add = gameForm =>
  gamesRef().push(mapToFirebaseGame(gameForm))
    .then(ref => {
      const gameId = ref.getKey();
      const groupId = gameForm.groupID;
      addGameToGroup(groupId, gameId);
      return gameId;
    });

export const updateGame = (gameID, gameForm) =>
  gameRef(gameID).update(mapToFirebaseGame(gameForm));

const mapToGame = (gameSnap, imageUrl, groupName) => {
  const dbGame = gameSnap.val();

  return {
    uid: gameSnap.key,
    name: dbGame.gameName || '',
    user: dbGame.user || '',
    startTime: dbGame.startTime && new Date(dbGame.startTime),
    attending: dbGame.attending || 0,
    groupCount: dbGame.groupCount || 0,
    ticketsPurchased: dbGame.ticketsPurchased || 0,
    maxTickets: dbGame.maxTickets || 0,
    maxTicketsPerPlayer: dbGame.maxTicketsPerPlayer || 0,
    numberOfPrizes: dbGame.numberOfPrizes || 0,
    groupID: dbGame.groupID || '',
    ticketPrice: dbGame.ticketPrice || '',
    tickets: dbGame.tickets,
    imageUrl,
    groupName,
  };
};

export const fetchGame = gameId =>
  gameRef(gameId).once('value')
    .then(gameSnap => mapToGame(gameSnap));

export const fetchGamesForGroup = group => {
  const allPromises = groupGamesRef(group.id).once('value')
    .then(snapshot => {
      const promises = [];
      snapshot.forEach(childSnapshot => {
        const gameId = childSnapshot.key;
        const promise = gameRef(gameId).once('value')
          .then(gameSnap => mapToGame(gameSnap, group.imageUrl, group.groupName));
        promises.push(promise);
      });
      return Promise.all(promises);
    });

  return allPromises.then(games => games.map(game => ({
    id: game.key,
    ...game
  })));
};

export const fetchGames = playerId => {
  const allPromises = groupsApi.fetchPlayerGroups(playerId)
    .then(groups => {
      const promises = [];
      groups.forEach(group => {
        promises.push(fetchGamesForGroup(group));
      });
      return Promise.all(promises);
    });

  return allPromises.then(games => games.flat());
};

export const deleteGame = (groupId, gameId) => {
  gameRef(gameId)
    .remove();
  return groupGameRef(groupId, gameId).remove();
};

export const onGameChange = (gameId, successCallback, errorCallback) => {
  gameRef(gameId).on('value', successCallback, errorCallback);
};

export const removeGameListener = gameId => gameRef(gameId).off();

export const onGameRunnerChange = (gameId, successCallback, errorCallback) => {
  gameRunnerRef(gameId).on('value', successCallback, errorCallback);
};

export const removeGameRunnerListener = gameId => gameRunnerRef(gameId).off();

export const onTicketsChange = (gameId, successCallback, errorCallback) => {
  ticketsRef(gameId).on('value', successCallback, errorCallback);
};

export const removeTicketsListener = gameId => ticketsRef(gameId).off();

export const onPlayerTicketsChange = (gameId, playerId, successCallback, errorCallback) => {
  ticketRef(gameId, playerId).on('value', successCallback, errorCallback);
};

export const removePlayerTicketsListener = (gameId, playerId) => ticketRef(gameId, playerId).off();

export const buyTicket = (gameId, playerId) => {
  const games = fetchGame(gameId);
  games.then(game => {
    const existingTickets = game.tickets ? (game.tickets[playerId] ?? 0) : 0;
    const transaction = {
      amount: (game.ticketPrice * (existingTickets + 1)),
      date: new Date().getTime(),
      type: 'purchase'
    };
    transactionRef(game.groupID, playerId, gameId).set(transaction);
  }, ticketRef(gameId, playerId).once('value')
    .then(snap => ticketRef(gameId, playerId).set(snap ? (snap.val() + 1) : 1)));
};

export const sellTicket = (gameId, playerId) => {
  const games = fetchGame(gameId);
  games.then(game => {
    const existingTickets = game.tickets ? (game.tickets[playerId] ?? 0) : 0;
    const transaction = {
      amount: (game.ticketPrice * (existingTickets - 1)),
      date: new Date().getTime(),
      type: 'purchase'
    };
    transactionRef(game.groupID, playerId, gameId).set(transaction);
  }, ticketRef(gameId, playerId).once('value')
    .then(snap => {
      ticketRef(gameId, playerId).set(snap ? (snap.val() - 1) : 0);

      if (snap && ((snap.val() - 1) === 0)) {
        ticketRef(gameId, playerId).remove();
      }
    }));
};

export const fillTestData = gameId => {
  const allPromises = playersApi.fetchPlayers()
    .then(players => {
      const promises = [];
      for (let i = 0; i < 5; i += 1) {
        const rndIndex = Math.round(players.length * Math.random());
        const playerId = players[rndIndex].uid;
        const tickets = Math.round(1 + 2 * Math.random());
        promises.push(ticketRef(gameId, playerId).set(tickets));
      }
      return Promise.all(promises);
    });

  return allPromises;
};

// Remove all sold tickets, and remove gameRunner entry
export const resetGame = gameId => {
  ticketsRef(gameId).remove();
  return gameRunnerRef(gameId).remove();
};
