import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, Redirect } from 'react-router-dom';
import {
  FormField,
  H1,
  Button,
  Page,
} from 'rf-ui';

import { RemoteData, NotAuthorized } from 'rf-comp';
import { Formik, Form } from 'formik';
import { isAuthorized } from 'rf-lib';
import * as Yup from 'yup';
import * as paths from 'rf-lib/routePaths';
import FillTestData from './FillTestData';
import ResetGame from './ResetGame';
import GameFormDetails from './GameFormDetails';
import * as a from './actions';

const validationSchema = Yup.object({
  gameName: Yup.string()
    .max(40, 'Spillnavnet er for langt (maks. 40 tegn)')
    .required('Vennligst oppgi et spillnavn'),
  groupID: Yup.string()
    .required('Vennligst velg spillergruppe'),
  startTime: Yup.date()
    .required('Vennligst velg starttid'),
  maxTicketsPerPlayer: Yup.number()
    .required('Vennligst oppgi max antall billetter per spiller')
    .min(1, 'Hver spiller må ha minst 1 billett')
    .max(100, 'Maks. 100 billetter per spiller'),
  numberOfPrizes: Yup.number()
    .required('Vennligst oppgi antall premier')
    .max(10, 'Maks. antall premier per spill er 10')
    .min(1, 'Minimum 1 premie'),
  ticketPrice: Yup.number()
    .min(1, 'Billettprisen må være minimum 1')
    .max(100, 'Maks. 100 kr per billett')
    .required('Vennligst oppgi billettpris'),
  maxTickets: Yup.number()
    .min(1, 'Antall billetter i omløp må være mer enn 0')
    .max(100, 'Maks. antall billetter er 100')
});

const GameForm = ({
  authUser, updateGame, game, ...props
}) => {
  if (!isAuthorized('admin', game.groupID, authUser)) {
    return <NotAuthorized message="You are not authorized to edit this game" />;
  }

  const history = useHistory();

  const initialGame = game
    ?
    {
      gameName: game.name,
      ...game,
      startTime: game.startTime
        ? new Date(game.startTime)
        : undefined,
    }
    : {
      gameName: '',
      groupID: '',
      ticketsPurchased: 0,
      startTime: new Date(Date.now() + 1000 * 60 * 60 * 24),
      maxTickets: 0,
      maxTicketsPerPlayer: 0,
      numberOfPrizes: 0,
      ticketPrice: 0
    };

  const handleUpdate = values => {
    const {
      gameName,
      groupID,
      startTime,
      maxTickets,
      maxTicketsPerPlayer,
      numberOfPrizes,
      ticketPrice,
      ticketsPurchased
    } = values;

    const payload = {
      gameName,
      groupID,
      startTime: startTime || new Date(Date.now() + 1000 * 60 * 60 * 24),
      maxTickets,
      maxTicketsPerPlayer,
      numberOfPrizes,
      ticketPrice,
      ticketsPurchased,
    };

    updateGame(values.uid, payload);
  };

  return (
    <>
      <H1>Rediger spillet!</H1>
      <Formik
        initialValues={initialGame}
        validationSchema={validationSchema}
        onSubmit={values => (handleUpdate(values))}
      >
        <Form>
          <FormField
            name="gameName"
            title="Navn på nytt spill"
          />
          <GameFormDetails />
          <Button title="Lagre" type="submit" />
          <Button title="Tilbake" onClick={() => history.push(paths.DASHBOARD)} />
          <FillTestData gameId={game.uid} />
          <ResetGame gameId={game.uid} />
          <RemoteData
            type={props.updateGameRemoteData}
            loading={() => <span>Oppdaterer spill...</span>}
            failure={err => <span>Feil: {err.message}</span>}
            success={() => <span><Redirect push to={paths.DASHBOARD} /> </span>}
          />
        </Form>
      </Formik>
    </>
  );
};

GameForm.propTypes = {
  authUser: PropTypes.object,
  game: PropTypes.object,
  updateGame: PropTypes.func.isRequired,
  updateGameRemoteData: PropTypes.object.isRequired
};

const EditGame = ({ fetchGame, currentGameState, ...props }) => {
  useEffect(() => {
    fetchGame(props.match.params.id);
    return (() => props.resetUpdateState());
  }, []);

  return (
    <Page>
      <RemoteData
        type={currentGameState}
        loading={() => <span>Laster spill...</span>}
        failure={err => <span>Feil: {err.message}</span>}
        success={game =>
          (
            <GameForm
              authUser={props.authUser}
              updateGameRemoteData={props.updateGameRemoteData}
              updateGame={props.updateGame}
              game={game} />
          )}
      />
    </Page>
  );
};

EditGame.propTypes = {
  authUser: PropTypes.object,
  fetchGame: PropTypes.func.isRequired,
  updateGame: PropTypes.func.isRequired,
  updateGameRemoteData: PropTypes.object.isRequired,
  resetUpdateState: PropTypes.func.isRequired,
  currentGameState: PropTypes.object.isRequired,
  match: PropTypes.object,
};

const mapStateToProps = ({ session, games }) => ({
  authUser: session.authUser,
  updateGameRemoteData: games.updateGameRemoteData,
  currentGameState: games.currentGameState,
});

const mapDispatchToProps = dispatch => ({
  updateGame: a.updateGame(dispatch),
  fetchGame: a.fetchGame(dispatch),
  resetUpdateState: a.resetUpdateState(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditGame);
