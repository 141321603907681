import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import {
  FormField,
  H1,
  Button,
  Page,
} from 'rf-ui';
import { isAuthorized } from 'rf-lib';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { games as gamesAPI } from 'rf-api';
import { NotAuthorized } from 'rf-comp';
import * as paths from 'rf-lib/routePaths';
import GameFormDetails from './GameFormDetails';

const NewGame = ({ authUser }) => {
  const { groupId } = useParams();

  if (!isAuthorized('admin', groupId, authUser)) {
    return <NotAuthorized message="You are not authorized to create a new game in this group" />;
  }

  return (
    <Page>
      <GameForm groupId={groupId} />
    </Page>
  );
};

const validationSchema = Yup.object({
  gameName: Yup.string()
    .max(40, 'Spillnavnet er for langt (maks. 40 tegn)')
    .required('Vennligst oppgi et spillnavn'),
  groupID: Yup.string()
    .required('Vennligst velg spillergruppe'),
  startTime: Yup.date()
    .required('Vennligst velg starttid'),
  maxTicketsPerPlayer: Yup.number()
    .required('Vennligst oppgi max antall billetter per spiller')
    .min(1, 'Hver spiller må ha minst 1 billett')
    .max(100, 'Maks. 100 billetter per spiller'),
  numberOfPrizes: Yup.number()
    .required('Vennligst oppgi antall premier')
    .max(10, 'Maks. antall premier per spill er 10')
    .min(1, 'Minimum 1 premie'),
  ticketPrice: Yup.number()
    .min(0, 'Billettprisen må være minimum 1')
    .max(100, 'Maks. 100 kr per billett')
    .required('Vennligst oppgi billettpris'),
  maxTickets: Yup.number()
    .min(1, 'Antall billetter i omløp må være mer enn 0')
    .max(100, 'Maks. antall billetter er 100')
});

const GameForm = ({ groupId }) => {
  const [error] = useState('');

  const history = useHistory();

  const initialGame = {
    gameName: '',
    groupID: groupId,
    startTime: new Date(Date.now() + 1000 * 60 * 60 * 24),
    ticketsPurchased: 0,
    maxTickets: 0,
    maxTicketsPerPlayer: 0,
    numberOfPrizes: 0,
    ticketPrice: 0
  };

  return (
    <>
      <H1>Lag et nytt spill!</H1>
      <Formik
        initialValues={initialGame}
        validationSchema={validationSchema}
        onSubmit={(gameForm, { setSubmitting }) => {
          gamesAPI.add(gameForm).then(setSubmitting(false)).then(
            () => history.push(paths.DASHBOARD)
          );
        }}
      >
        <Form>
          <FormField
            name="gameName"
            title="Navn på nytt spill"
        />
          <GameFormDetails />
          <Button title="Lagre" type="submit" />
          <Button title="Tilbake" onClick={() => history.push(paths.DASHBOARD)} />
        </Form>
      </Formik>
      {error}
    </>
  );
};

NewGame.propTypes = {
  authUser: PropTypes.object
};

GameForm.propTypes = {
  groupId: PropTypes.string
};

const mapStateToProps = ({ session }) => ({
  authUser: session.authUser
});

export default connect(mapStateToProps)(NewGame);
